const tabbarSecondLinks = [
  {
    to: "#Техподдержка",
    src: "/img/images/tabbar/tabbar__icon_16.svg",
    text: "Техподдержка",
    userRole: [30, 10]
  },
  {
    to: "#Условия",
    src: "/img/images/tabbar/tabbar__icon_17.svg",
    text: "Условия использования",
    userRole: [30, 10]
  }
];

export default tabbarSecondLinks;
